<template>
  <div class="flex justify-center p-3 pb-10 mb-20 overflow-y-auto overscroll-x-none  bg-gray-100">
    <div class="flex flex-col w-full bg-gray-100 space-y-6">
      <!-- <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
        {{content?.title}}
      </h2> -->
      <!-- ข้อมูลงาน -->
      
      <event-info
        :content="content?.info"
        :eventActive="eventActive"        
        :eventLang="eventLang"        
        :eventShowRandomWish="eventShowRandomWish"        
        :eventLocation="eventLocation"
        :eventProvince="eventProvince"
        :provinceList="provinceList"
        :eventDate="eventDate"
        :eventTime="eventTime"
        :eventTimeStart="eventTimeStart"
        :saveEventInfo="saveEventInfo"
        @isActived="updateEventActive"
        @eventDate="updateEventDate"
        @eventTime="updateEventTime"
        @eventTimeStart="updateEventTimeStart"
        @eventLocation="updateEventLocation"
        @eventProvince="updateEventProvince"
        @eventLang="updateEventLang"
        @eventShowRandomWish="updateEventShowRandomWish"
      />

      <!-- ข้อมูลเจ้าสาว -->
      <event-bride  :content="content?.bride" :inValidText="content?.inValidText" :bride="bride" :saveEventInfo="saveEventInfo" />

      <!-- ข้อมูลเจ้าบ่าว -->
      <event-groom  :content="content?.groom" :inValidText="content?.inValidText" :groom="groom" :saveEventInfo="saveEventInfo"/>

      <!-- ข้อมูลที่อยู่การจัดส่ง -->      
      <ShippingInfo :content="content?.shipping"/>

      <!--ข้อมูลรูปแบบการทำหนังสือ -->      
      <event-produce-book :content="content?.produceBook"/>

      <!-- ข้อมูล Font -->  
      <!-- <event-custom-font    
        :content="content?.font"
        :eventFont="eventFont"
        @fontSelected="updateEventFont"
        :fonts="fonts"
        :bride="bride"
        :groom="groom"
        :dateCustom="dateCustom"
        :eventTime="eventTime"
        :eventTimeStart="eventTimeStart"
        :saveChangeFont="saveChangeFont"
      />  -->

      <!-- ข้อมูล custom thankyou page -->  
      <!-- <event-custom-thankyou    
        :content="content?.customThankyou"
        :titles="thankyouList.titles"
        :descriptions="thankyouList.descriptions"
        :settingThankyou="settingThankyou"
        :saveCustomThankyou="saveCustomThankyou"
      /> -->

    </div>
  </div>
</template>

<script setup>
import { provinceList } from "~/public/data/province";
import { useEventStore } from "~/stores/event";
import ShippingInfo from "~/components/event/ShippingInfo"

import dayjs from "dayjs";

import { useContentWish } from '~/composables/content/v2/useContentWish'
const { content } = useContentWish('wish-info')

const useEvent = useEventStore();

const fonts = [
  "Callem",
  "Great Vibes",
  "Dancing Script",
  "Cookie",
  "Parisienne",
];

const thankyouList = reactive({
  titles: [
    'THANKYOU',
    'ขอบคุณที่มาร่วมงาน'
  ],
  descriptions: [
    'For Joining Us!',
    'From the bottom of our hearts',
    'For being part of our special day!',
    'รับของชำร่วยได้ที่โต๊ะลงทะเบียน',
    'ขอบคุณทุกท่านที่มาร่วมงานของเรา',
    'ความรักและกำลังใจของท่านมีความหมายมากสำหรับเรา',
  ]
})

//Event Init
const eventData = ref();

//EventInfo
const eventActive = ref(false);
const eventLang = ref('th');
const eventShowRandomWish = ref(true);
const eventLocation = ref("");
const eventProvince = ref("");
const eventShippingInfo = ref({})
const eventDate = ref("");
const eventTime = ref("00:00");
const eventTimeStart = ref("00:00");
const bride = ref({});
const groom = ref({});

//Optional
const eventHashtag = ref("");
const timerSettimg = ref({});

//Font
const eventFont = ref("Callem");
//thankyou
const settingThankyou = ref({})

const dateCustom = computed(() => {
  return {
    year: dayjs(eventDate.value).format("YYYY"),
    month: dayjs(eventDate.value).format("MMM"),
    nameWeek: dayjs(eventDate.value).format("dddd"),
    day: dayjs(eventDate.value).format("D"),
    default:eventDate.value
  };
});


const init = async () => {
  const event = useEvent.event ;
  if(!event) return 
  eventData.value = event;
  eventActive.value = event.active;
  eventLang.value = event?.lang || 'th';
  eventShowRandomWish.value = event?.showRandomWish || true;
  eventLocation.value = event.information.location;
  eventProvince.value = event.information.province;
  // eventShippingInfo.value = event?.shipping || {name:"", mobile:"", address:"", produce_book_date: ""};
  eventDate.value = event.information.date;
  eventTime.value = event.information.time;  // เข้า/เย็น
  eventTimeStart.value = event.information.eventTime;  
  bride.value = event.information.bride;
  groom.value = event.information.groom;
  eventHashtag.value = event.information.hashtag;
  timerSettimg.value = event.settings.timer;
  eventFont.value = event.settings.welcomeScreen.font;
  settingThankyou.value = event.settings.thankyouScreen || {
    title: 'THANKYOU',
    description: 'For Joining Us!'
  }
  
};

onBeforeMount(async()=>await init())
//watchEffect(async() =>await init())


const updateEventActive = (value)=>{
  eventActive.value = value
}
const updateEventDate = (value)=>{
  eventDate.value = value
}
const updateEventTime = (value)=>{
  eventTime.value = value
}

const updateEventTimeStart = (value)=>{
  eventTimeStart.value = value
}

const updateEventFont= (value)=>{
  eventFont.value = value
}
const updateEventLocation= (value)=>{
  eventLocation.value = value
}
const updateEventProvince= (value)=>{
  eventProvince.value = value
}
const updateEventLang= (value)=>{
  eventLang.value = value
}
const updateEventShowRandomWish= (value)=>{
  eventShowRandomWish.value = value
}


const saveEventInfo = async () => {
  const initEventInfo = eventData.value.information;
  const information = {
    ...initEventInfo,
    bride: { ...initEventInfo.bride, ...bride.value },
    groom: { ...initEventInfo.groom, ...groom.value },
    location: eventLocation.value ,
    province:eventProvince.value ,
    // shippingInfo: { ...initEventInfo.shippingInfo, ...eventShippingInfo.value },
    date: dayjs(eventDate.value).format("YYYY-MM-DD"),
    time: eventTime.value ,
    eventTime: eventTimeStart.value || "00:00",
    lang: eventLang.value, 
    showRandomWish: eventShowRandomWish.value
  };

  await useEvent.updateEvent({ information, active: eventActive.value });
};

const saveChangeOptional = async () => {
  const initSettingInfo = eventData.value.settings;
  const settings = { ...initSettingInfo, timer: timerSettimg.value };
  const information = {
    ...eventData.value.information,
    hashtag: eventHashtag.value || "",
  };

  console.log("settings", settings)
  console.log("information", information)

  await useEvent.updateEvent({ settings , information });
};

const saveChangeFont = async () => {
  const initSettingInfo = eventData.value.settings;
  const settings = { ...initSettingInfo, welcomeScreen: {font:eventFont.value }};
  await useEvent.updateEvent({ settings });
};

const saveCustomThankyou = async (data) => {
  const initSettingInfo = eventData.value.settings;
  const settings = { ...initSettingInfo, thankyouScreen: data };
  settingThankyou.value = data
  console.log(settings)

  await useEvent.updateEvent({ settings });

}


</script>

<style scoped>
/* For desktop and large screens by default */
.datepicker-width-full {
  width: 90vw !important;
}

/* For iPads (Tablets) in portrait and landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
 .datepicker-width-full {
    width: 95vw !important; /* Adjust as needed */
  }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
 .datepicker-width-full {
    width: 80vw !important; /* Adjust as needed */
  }
}
</style>
